import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M 26.353 50.888 L 32.259 50.314 C 32.614 52.296 33.335 53.752 34.422 54.682 C 35.509 55.611 36.976 56.076 38.821 56.076 C 40.776 56.076 42.25 55.663 43.241 54.836 C 44.232 54.009 44.728 53.041 44.728 51.934 C 44.728 51.223 44.519 50.618 44.102 50.119 C 43.685 49.62 42.957 49.185 41.918 48.816 C 41.207 48.57 39.587 48.133 37.058 47.504 C 33.804 46.697 31.521 45.706 30.208 44.53 C 28.362 42.876 27.439 40.86 27.439 38.481 C 27.439 36.95 27.873 35.517 28.742 34.184 C 29.61 32.851 30.861 31.836 32.495 31.139 C 34.128 30.442 36.1 30.093 38.411 30.093 C 42.184 30.093 45.025 30.92 46.932 32.574 C 48.839 34.229 49.841 36.437 49.937 39.198 L 43.866 39.465 C 43.607 37.92 43.05 36.809 42.195 36.132 C 41.34 35.455 40.059 35.117 38.35 35.117 C 36.586 35.117 35.205 35.479 34.207 36.204 C 33.564 36.669 33.243 37.291 33.243 38.07 C 33.243 38.781 33.544 39.39 34.146 39.896 C 34.911 40.538 36.771 41.208 39.724 41.905 C 42.677 42.602 44.861 43.324 46.276 44.069 C 47.691 44.814 48.798 45.833 49.598 47.125 C 50.398 48.417 50.798 50.013 50.798 51.913 C 50.798 53.636 50.319 55.249 49.362 56.753 C 48.405 58.257 47.052 59.375 45.302 60.106 C 43.552 60.837 41.371 61.203 38.76 61.203 C 34.959 61.203 32.04 60.325 30.003 58.568 C 27.966 56.811 26.749 54.251 26.353 50.888 Z"          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
